// Import CSS
import styles from '../css/app.pcss';

// Import images
import logoImg from '../img/gcivil-logo.png';
import bgImg from '../img/bg-texture.jpg';
import homeImg from '../img/home-photo.jpg';
import homeImg2 from '../img/home-photo2.jpg';

// Import Hooper
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

// App main
const main = async () => {
  // Async load the vue module
  const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    
  // Create our vue instance
  const vm = new Vue({
      el: "#page-container",
      components: {
          'global-header': () => import(/* webpackChunkName: "global-header" */ '../vue/components/Global/Header.vue'),
          Hooper,
          Slide
      },
      data: {
      },
      methods: {
      },
      mounted() {
      },
  });
  
  return vm;
};

// Execute async function
main().then( (vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}